import React from 'react';
import { Library, Item } from '../@types/common';
import { ChevronRightIcon, ChevronDownIcon } from '@heroicons/react/24/outline';
import Loader from './Loader';

interface Props {
    library: Library;
    expanded: boolean;
    onLibraryClick: (id: number) => void;
    onItemSelect: (type: 'Metric' | 'Concept', item: Item) => void;
    addItem: (type: 'Metric' | 'Concept') => void;
    currentItemSelected: Item | null;
}

const LibraryBox: React.FC<Props> = ({ library, expanded, onLibraryClick, onItemSelect, addItem, currentItemSelected }) => {
    return (
        <div className="border p-2 rounded m-2 hover:shadow-lg transition-shadow duration-200 flex">
            <div className="flex-grow">
                <div className="flex justify-between items-center hover:cursor-pointer" onClick={() => onLibraryClick(library.id)}>
                    <span className="cursor-pointer ">
                        {library.name}
                    </span>
                    <div>
                        <span className='px-2'>Learn more</span>
                        {expanded ? <ChevronDownIcon className='h-5 w-5 inline' /> : <ChevronRightIcon className='h-5 w-5 inline' />}
                    </div>
                </div>
                {expanded && (
                    <>
                        {!library.items ? (
                            <div className='m-2'><Loader /></div>
                        ) : (
                            <div className="pl-2 mt-2">
                                <div className="flex justify-between items-center mb-2">
                                    <div>Metrics</div>
                                </div>
                                <div className="flex justify-between items-center mb-2">
                                    <div className="border flex-grow overflow-auto h-full max-h-[calc(50vh-4rem)]">
                                        {library.items.filter(it => it.type === 'Metric').map((it) => (
                                            <div
                                                className={`p-1 rounded ${(it.id === currentItemSelected?.id && currentItemSelected?.type === "Metric") ? 'font-semibold' : ''}`}
                                                key={it.id}
                                            >
                                                {it.name}
                                                <div className='ml-2'>
                                                    {it.desc}
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                    <div className="flex flex-col items-center p-4">
                                        <div className="mb-2">{library.price ? `$${library.price} / Month` : "$X / Month"}</div>
                                        <button className="bg-blue-500 text-white px-4 py-2 rounded">Subscribe to Library</button>
                                    </div>
                                </div>
                            </div>
                        )}
                    </>
                )}
            </div>
        </div >
    );
}

export default LibraryBox;
