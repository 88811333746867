import React, { createContext, useState, useContext, useEffect } from "react";
import { Library } from "../@types/common"

interface LibraryContextData {
    library: Library | null;
    setLibrary: (library: Library | null) => void;
    libraries: Library[] | null;
    setLibraries: (libraries: Library[] | null) => void;
}

const LibraryContext = createContext<LibraryContextData | undefined>(undefined);

const useLibrary = () => {
    const context = useContext(LibraryContext);
    if (!context) {
        throw new Error("useLibrary must be used within an LibraryProvider");
    }
    return context;
};

const LibraryProvider: React.FC<{ children: React.ReactNode }> = ({
    children,
}) => {
    const [library, setLibrary] = useState<Library | null>(null);
    const [libraries, setLibraries] = useState<Library[] | null>([]);

    const fetchLibraries = async () => {
        const response = await fetch(`/api/get_libraries?company=${"ABC Healthcare"}`);
        const data = await response.json();
        setLibraries(data.libraries);
        console.log(data)
    };

    useEffect(() => {
        fetchLibraries();
    }, []);

    return (
        <LibraryContext.Provider value={{ library, setLibrary, libraries, setLibraries }}>
            {children}
        </LibraryContext.Provider>
    );
};

export { LibraryProvider, useLibrary };
