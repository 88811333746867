import type { LexicalEditor } from 'lexical';

import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { TextNode } from 'lexical';
import { useEffect } from 'react';
import { Item } from '../@types/common';

import { $createMetricNode, MetricNode } from "../lexical/MetricNode";

function findAndTransformBracketText(node: TextNode, metrics: Item[], concepts: Item[]): null | TextNode {
    const text = node.getTextContent();
    const regex = /\[(.*?)\]/g;
    let match;

    while ((match = regex.exec(text)) !== null) {
        const bracketText = match[0];
        const innerText = match[1];

        let isMetric = false;
        let status = "";
        let type = "";
        let description = "";
        let bracketStyle;

        // Search in metrics
        let object = metrics.find(metric => metric.name === innerText);
        if (object) {
            isMetric = true;
            status = object.status!;
            type = object.type;
            description = object.desc!;
        } else {
            // Search in concepts
            object = concepts.find(concept => concept.name === innerText);
            if (object) {
                status = object.status!;
                type = object.type;
                description = object.desc!;
            }
        }

        bracketStyle = isMetric ? 'text-purple-600' : 'text-blue-600';
        const start = match.index;
        const end = regex.lastIndex;
        let targetNode;

        if (start === 0) {
            [targetNode] = node.splitText(end);
        } else {
            [, targetNode] = node.splitText(start, end);
        }

        const bracketNode = $createMetricNode(bracketText, bracketStyle, status, type, description);
        targetNode.replace(bracketNode);
        return bracketNode;
    }

    return null;
}

function textNodeTransform(node: TextNode, metrics: Item[], concepts: Item[]): void {
    let targetNode: TextNode | null = node;

    while (targetNode !== null) {
        if (!targetNode.isSimpleText()) {
            return;
        }

        targetNode = findAndTransformBracketText(targetNode, metrics, concepts);
    }
}

function useBracketText(editor: LexicalEditor, metrics: Item[], concepts: Item[]): void {
    useEffect(() => {
        if (!editor.hasNodes([MetricNode])) {
            throw new Error('BracketTextPlugin: BracketTextNode not registered on editor');
        }

        return editor.registerNodeTransform(TextNode, (node) => textNodeTransform(node, metrics, concepts));
    }, [editor]);
}

interface BracketTextPluginProps {
    metrics: Item[];
    concepts: Item[];
}

export default function BracketTextPlugin({ metrics, concepts }: BracketTextPluginProps): JSX.Element | null {
    const [editor] = useLexicalComposerContext();
    useBracketText(editor, metrics, concepts);
    return null;
}
