import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useAuth } from "../hooks/useAuth";
import { Link } from "react-router-dom";
import UserProfile from "./UserProfile";
import { ReactComponent as XolvedIcon } from "../svg/XolvedIcon.svg"

const AuthedRoute: React.FC = () => {
  const { isAuthenticated } = useAuth();

  return isAuthenticated ? (
    <>
      {/* <div className="flex justify-between items-center">
        <XolvedIcon className="h-10 w-10 ml-2 p-1" />
        <nav className="flex justify-end p-2">
          <ul className="flex gap-4">
            <li>
              <Link to="/">Xolved - Q&A</Link>
            </li>
            <li>
              <Link to="/metriccentral">Xolved - Metric Central</Link>
            </li>
            <li>
              <Link to="/conceptbuilder">Xolved - Concept Builder</Link>
            </li>
            <li>
            <Link to="/dataannotator">Xolved - Data Annotator</Link>
          </li>
            <li>
              <Link to="/metricstore">Xolved - Metric Store</Link>
            </li>
            <UserProfile className="h-6 w-6 relative" />
          </ul>
        </nav>
      </div> */}
      <Outlet />
    </>
  ) : (
    <Navigate to={"/login"} />
  );
};

export default AuthedRoute;
