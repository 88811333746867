import React from 'react';
import { CheckCircleIcon } from '@heroicons/react/24/outline'

interface TooltipProps {
    metric: string;
    status: string;
    type: string;
    desc: string;
    closeTooltip: () => void;
}

const Tooltip: React.FC<TooltipProps> = ({ metric, status, type, desc, closeTooltip }) => {
    if (!type) return (<div />);
    return (
        <div className="bg-white border border-black rounded-md p-2">
            <div className="flex justify-between items-center gap-2">
                <div className="font-semibold flex items-center">
                    {`${metric}`}
                    {status === "Verified" && <CheckCircleIcon className="h-5 w-5 ml-1 inline-block" />}
                </div>
                <button onClick={closeTooltip} className="text-xl font-bold">X</button>
            </div>
            <div>Type: {type}</div>
            <div>Description: {desc}</div>
        </div>
    );
};

export default Tooltip;
