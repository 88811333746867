import React from "react";
import Sidebar from "../components/Sidebar";

import StorePanel from "../components/StorePanel";

const MetricStore: React.FC = () => {
    return (
        <div className="App w-full h-screen relative flex">
            <Sidebar />
            <StorePanel />
        </div>
    );
};

export default MetricStore;