import React from "react";
import { GoogleLogin, CredentialResponse } from "@react-oauth/google";
import { useToast } from "../hooks/useToast";
import { useAuth } from "../hooks/useAuth";
import { useNavigate } from "react-router-dom";
import * as api from "../api";

interface LoginProps {
  onLogin: () => void;
}

const Login: React.FC<LoginProps> = ({ onLogin }) => {
  const toast = useToast();
  const { setUserProfile } = useAuth();
  const navigate = useNavigate();

  const handleSuccess = async (response: CredentialResponse) => {
    try {
      const userProfile = await api.googleOAuth(response.credential!);
      setUserProfile(userProfile);
      toast.success("Successfully logged in!");
      navigate("/");
    } catch (error: any) {
      error?.message && toast.error(error.message);
    }
  };

  return (
    <div className="flex items-center justify-center h-screen">
      <GoogleLogin onSuccess={handleSuccess} />
    </div>
  );
};

export default Login;
