import {
  $applyNodeReplacement,
  DOMConversionMap,
  DOMConversionOutput,
  DOMExportOutput,
  EditorConfig,
  LexicalNode,
  SerializedTextNode,
  Spread,
  TextNode,
} from "lexical";
import React from 'react';
import ReactDOM from 'react-dom';
import Tooltip from "../components/Tooltip";

export type SerializedMetricNode = Spread<
  {
    metric: string;
    color: string;
    status: string;
    item: string;
    description: string;
  },
  SerializedTextNode
>;

class MetricNode extends TextNode {
  __metric: string;
  __color: string;
  __status: string;
  __item: string;
  __description: string;

  static getType(): string {
    return "metric";
  }

  static clone(node: MetricNode): MetricNode {
    return new MetricNode(node.__metric, node.__color, node.__status, node.__type, node.__description, node.__text, node.__key);
  }

  constructor(metric: string, color: string, status: string, item: string, description: string, text?: string, key?: string) {
    super(text ?? metric, key);
    this.__metric = metric;
    this.__color = color;
    this.__status = status;
    this.__item = item;
    this.__description = description;
  }

  handleClick = (e: any) => {
    const popup = document.createElement('div');

    // Adjust styles of popup to be positioned correctly
    popup.style.position = 'absolute';

    // Use the event's clientX and clientY to determine the click position
    const rect = e.target.getBoundingClientRect();
    popup.style.left = `${rect.left}px`;
    popup.style.top = `${rect.bottom}px`;  // Place it below the clicked text

    document.body.appendChild(popup);

    const onDocumentClick = (e: any) => {
      // If the target of the click event is not within the tooltip, close the tooltip
      if (!popup.contains(e.target)) {
        closeTooltip();
      }
    }

    document.addEventListener('click', onDocumentClick);

    const closeTooltip = () => {
      ReactDOM.unmountComponentAtNode(popup);
      document.body.removeChild(popup);
      document.removeEventListener('click', onDocumentClick);
    };

    ReactDOM.render(<Tooltip metric={this.__metric} status={this.__status} type={this.__item} desc={this.__description} closeTooltip={closeTooltip} />, popup);
    e.stopPropagation();
  };

  createDOM(config: EditorConfig): HTMLElement {
    const dom = super.createDOM(config);
    dom.classList.add(this.__color || "text-black");

    // Make sure to pass the event to the handler
    dom.addEventListener("click", this.handleClick);

    return dom;
  }

  exportDOM(): DOMExportOutput {
    const element = document.createElement("span");
    element.setAttribute("data-lexical-metric", "true");
    element.textContent = this.__text;
    return { element };
  }

  static importDOM(): DOMConversionMap | null {
    return {
      span: (domNode: HTMLElement) => {
        if (!domNode.hasAttribute("data-lexical-metric")) {
          return null;
        }
        return {
          conversion: convertMetricElement,
          priority: 1,
        };
      },
    };
  }

  static importJSON(serializedNode: SerializedMetricNode): MetricNode {
    const node = $createMetricNode(serializedNode.metric, serializedNode.color, serializedNode.status, serializedNode.item, serializedNode.description);
    node.setTextContent(serializedNode.text);
    node.setFormat(serializedNode.format);
    node.setDetail(serializedNode.detail);
    node.setMode(serializedNode.mode);
    node.setStyle(serializedNode.style);
    return node;
  }

  exportJSON(): SerializedMetricNode {
    return {
      ...super.exportJSON(),
      metric: this.__metric,
      color: this.__color,
      status: this.__status,
      item: this.__item,
      description: this.__description,
      type: MetricNode.getType(),
      version: 1,
    };
  }

  isTextEntity(): boolean {
    return true;
  }

  canInsertTextBefore(): boolean {
    return false;
  }

  canInsertTextAfter(): boolean {
    return false;
  }
}

function convertMetricElement(
  domNode: HTMLElement
): DOMConversionOutput | null {
  const textContent = domNode.textContent;
  if (textContent !== null) {
    return {
      node: $createMetricNode(textContent, "", "", "", ""),
    };
  }
  return null;
}

function $createMetricNode(metric: string, color: string, status: string, type: string, description: string): MetricNode {
  const metricNode = new MetricNode(metric, color, status, type, description);
  metricNode.setMode("token").toggleDirectionless();
  return $applyNodeReplacement(metricNode);
}

function $isMetricNode(
  node: LexicalNode | null | undefined
): node is MetricNode {
  return node instanceof MetricNode;
}

export {
  MetricNode,
  $createMetricNode,
  $isMetricNode,
};