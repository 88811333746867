import React, { Fragment } from "react";
import { BrowserRouter as Router, Route, Routes, useNavigate } from "react-router-dom";
import MetricCentral from "./pages/MetricCentral";
import ConceptCentral from "./pages/ConceptCentral";
import DataDictionary from "./pages/DataDictionary";
import MetricStore from "./pages/MetricStore";
import Login from "./pages/Login";
import AuthedRoute from "./components/AuthedRoute";

const RedirectToMetricCentral: React.FC = () => {
  const navigate = useNavigate();
  React.useEffect(() => {
    navigate('/metriccentral');
  }, [navigate]);

  return null; // Render nothing.
};

const App: React.FC = () => {
  return (
    <Router>


      <Routes>
        <Route path="/login" element={<Login onLogin={() => { }} />} />
        <Route element={<AuthedRoute />}>
          <Route path="/" element={<RedirectToMetricCentral />} />
          <Route path="/metriccentral" element={<MetricCentral />} />
          <Route path="/conceptbuilder" element={<ConceptCentral />} />
          <Route path="/dataannotator" element={<DataDictionary />} />
          <Route path="/metricstore" element={<MetricStore />} />
        </Route>
      </Routes>

    </Router>
  );
};

export default App;
