import React from "react";

interface TopBarProps {
    title: string;
}

const TopBar: React.FC<TopBarProps> = ({ title }) => {
    return (
        <div className="right-panel-bg absolute top-0 left-0 w-full z-10 h-10 flex items-center justify-between px-8">
            <span className="text-lg font-bold ml-10">{title}</span>
            <span className="text-lg font-bold">ABC Healthcare</span>
        </div>
    );
};

export default TopBar;
