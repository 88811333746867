import React, { useEffect, useState } from 'react';
import { useTableData } from '../hooks/useTableData';
import { useAuth } from '../hooks/useAuth';
import { ReactComponent as StartIcon } from "../svg/start_icon.svg"
import Buttons from "./Buttons"
import { Table } from "../@types/common"
import { useToast } from "../hooks/useToast"

interface TableColumn {
    COLUMN_NAME: string;
    DATA_TYPE: string;
}

const MainPanel: React.FC = () => {
    const { table, setTable, tables, setTables } = useTableData();
    const [initTable, setInitTable] = useState<Table>(table);
    const { userProfile } = useAuth();
    const toast = useToast();

    const fetchTableData = async () => {
        const tableResponse = await fetch(`/api/gettableschema?table_name=${table.name}`);
        const tableData = await tableResponse.json();
        const columns = tableData.data[0];

        const columnNames = columns.map((column: TableColumn) => column.COLUMN_NAME);
        const columnTypes = columns.map((column: TableColumn) => column.DATA_TYPE);

        const deprecations: string[] = Array(columnNames.length).fill("N");
        const annotations: string[] = Array(columnNames.length).fill("");

        const annotationResponse = await fetch(`/api/getcolumnannotation?table_name=${table.name}`);
        const annotationData = await annotationResponse.json();
        const fetchedAnnotations = annotationData.data[0];

        fetchedAnnotations.forEach((annotation: { annotation: string, column_name: string, deprecated: string }) => {
            const position = columnNames.indexOf(annotation.column_name);
            if (position !== -1) {
                deprecations[position] = annotation.deprecated;
                annotations[position] = annotation.annotation;
            }
        });

        setTable({
            ...table,
            columnNames: columnNames,
            columnTypes: columnTypes,
            deprecations: deprecations,
            annotations: annotations
        });
        setInitTable({
            ...table,
            columnNames: columnNames,
            columnTypes: columnTypes,
            deprecations: deprecations,
            annotations: annotations
        });
    };

    useEffect(() => {
        if (table.name) {
            fetchTableData();
        }
    }, [table.name]);

    const handleCheckboxChange = (index: number) => {
        setTable({
            ...table, deprecations: table.deprecations?.map((value, idx) => idx === index ? (value === "N" ? "Y" : "N") : value)
        });
    };

    const handleDescriptionChange = (event: React.ChangeEvent<HTMLInputElement>, index: number) => {
        const value = event.target.value;
        setTable({
            ...table, annotations: table.annotations?.map((desc, idx) => idx === index ? value : desc)
        });
    };

    const buttonState = (buttonType: string) => {
        if (buttonType === 'Delete') return true;
        if (!table.name) return false;

        console.log(JSON.stringify(initTable) === JSON.stringify(table))
        return JSON.stringify(initTable) !== JSON.stringify(table);
    };

    const handleButtonClick = async (buttonType: string) => {
        let status = "";
        if (buttonType === "Delete") status = "Deprecated";
        if (buttonType === "Draft") status = "Draft";
        if (buttonType === "Verified") status = "Verified";

        if (!table) return;

        const data = {
            "table_name": table.name,
            "table_annotation": table.desc,
            "status": status,
            "column_name_list": table.columnNames,
            "column_data_type_list": table.columnTypes,
            "annotation_list": table.annotations,
            "deprecated_list": table.deprecations,
        };

        toast.info("Processing request...");
        const response = await fetch(`/api/saveannotation?email=${userProfile?.email}`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(data),
        });

        const responseData = await response.json();
        const updatedTable = { ...table, status };
        const updatedTables = tables.map((cur) => (cur.name === table.name) ? updatedTable : cur);
        setTable(updatedTable);
        setInitTable(updatedTable)
        setTables(updatedTables);
        if (responseData.message === "success") {
            toast.success("Saved successfully!", 750);
        } else {
            toast.error("Unable to save. Please try again.", 1500);
        }
    };

    if (!table.name) {
        return (
            <div className="space-y-4 grow max-w-2xl flex flex-col items-center justify-center">
                <StartIcon className="w-150 h-150" />
                <div className='font-semibold text-center'>To get started, select a table from the left to provide a description of the table and the fields within it</div>
                <div className='text-center'>Better data descriptions help the Xolved AI better use your data to accurately answer any questions asked</div>
            </div>
        );
    }

    return (
        <div className="space-y-4">
            <Buttons type={"table"} handleButtonClick={handleButtonClick} buttonState={buttonState} />
            <div>
                <input className="w-full border-2 p-2 bg-table text-white" type="text" placeholder="Name of selected table" value={table.name || ''} readOnly />
            </div>
            <div>
                <input className="w-full border-2 p-2 bg-gray-100" type="text" placeholder="Description of table" value={table.desc || ''} onChange={(e) => setTable({ ...table, desc: e.target.value })} />
            </div>
            <table className="w-full table-auto border-collapse border-2 border-black divide-y divide-gray-300">
                <thead>
                    <tr>
                        <th className="p-2 border-r border-gray-300 bg-table text-white">Field Name</th>
                        <th className="p-2 border-r border-gray-300 bg-table text-white">Data Type</th>
                        <th className="p-2 border-r border-gray-300 bg-table text-white">Constraints</th>
                        <th className="p-2 border-r border-gray-300 bg-gray-100">Do Not Use</th>
                        <th className="p-2 bg-gray-100">Description of field</th>
                    </tr>
                </thead>
                <tbody>
                    {table.columnNames?.map((column, index) => (
                        <tr key={column} className="border-b border-gray-300">
                            <td className="p-2 border-r border-gray-300 bg-table text-white">{column}</td>
                            <td className="p-2 border-r border-gray-300 bg-table text-white">{table.columnTypes?.[index]}</td>
                            <td className="p-2 border-r border-gray-300 bg-table text-white"></td>
                            <td className="p-2 border-r border-gray-300 bg-gray-100">
                                <input
                                    type="checkbox"
                                    checked={table?.deprecations?.[index] === "Y"}
                                    onChange={() => handleCheckboxChange(index)}
                                />
                            </td>
                            <td className="p-2 bg-gray-100">
                                <input
                                    type="text"
                                    className="w-full border-2 p-2"
                                    placeholder="Description"
                                    value={table.annotations?.[index] || ''}
                                    onChange={(event) => handleDescriptionChange(event, index)}
                                />
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    )
};

export default MainPanel;
