import React, { useEffect, useState } from 'react';
import { useTableData } from '../hooks/useTableData';
import { CheckCircleIcon, XMarkIcon, DocumentDuplicateIcon, TrashIcon } from '@heroicons/react/24/outline'
import { ReactComponent as EditIcon } from "../svg/EditIcon.svg"
import { ReactComponent as LoadIcon } from "../svg/LoadIcon.svg"

interface ButtonsProps {
    type: string;
    handleButtonClick: (buttonType: string) => void;
    buttonState: (buttonType: string) => boolean;
}

const Buttons: React.FC<ButtonsProps> = ({ type, handleButtonClick, buttonState }) => {
    const getButtonClass = (buttonType: string) => {
        if (buttonType === 'Verified' && type !== "table") {
            return buttonState(buttonType)
                ? "rounded-md border border-black px-2 w-[120px] bg-gray-100"
                : "rounded-md border border-black px-2 w-[120px] bg-zinc-400";
        }

        return buttonState(buttonType)
            ? "rounded-md border border-black px-2 w-[120px] bg-gray-100"
            : "rounded-md border border-gray-300 px-2 w-[120px] text-gray-300";
    };

    return (
        <div className="flex gap-4">
            <div className="flex gap-4">
                <button onClick={() => handleButtonClick('Verified')}
                    className={getButtonClass('Verified')}
                    disabled={!buttonState('VerifiedState')}>
                    Verify & Lock
                    <CheckCircleIcon className={"h-4 w-4 ml-1 inline-block"} />
                </button>
                {type === "Concept" &&
                    <button onClick={() => handleButtonClick('Draft')}
                        className={getButtonClass('Draft')}
                        disabled={!buttonState('Draft')}>
                        Save
                    </button>
                }
            </div>
            <div className="ml-auto flex gap-4">
                {type !== "table" &&
                    <button onClick={() => handleButtonClick('Duplicate')}
                        className={getButtonClass('Duplicate')}
                        disabled={!buttonState('Duplicate')}>
                        Duplicate {type}
                        <DocumentDuplicateIcon className={buttonState('Duplicate') ? "h-4 w-4 ml-1 inline-block" : "h-5 w-5 ml-1 inline-block text-gray-300"} />
                    </button>
                }
                <button onClick={() => handleButtonClick('Dismiss')}
                    className={getButtonClass('Dismiss')}
                    disabled={!buttonState('Dismiss')}>
                    Dismiss All Edits
                    <EditIcon className={buttonState('Dismiss') ? "h-5 w-5 ml-1 inline-block" : "h-5 w-5 ml-1 inline-block text-gray-300"} />
                </button>
                {type === "table" &&
                    <button onClick={() => handleButtonClick('Draft')}
                        className={getButtonClass('Draft')}
                        disabled={!buttonState('Draft')}>
                        Save as Draft
                        <LoadIcon className={buttonState('Draft') ? "h-5 w-5 ml-1 inline-block" : "h-5 w-5 ml-1 inline-block text-gray-300"} />
                    </button>
                }
                <button onClick={() => handleButtonClick('Delete')}
                    className={getButtonClass('Delete')}
                    disabled={!buttonState('Delete')}>
                    {type === "table" ? "Do Not Use Table" : `Delete ${type}`}
                    <TrashIcon className={buttonState('Delete') ? "h-4 w-4 ml-1 inline-block" : "h-5 w-5 ml-1 inline-block text-gray-300"} />
                </button>
            </div>
        </div>
    );
};

export default Buttons;
