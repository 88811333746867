import React from "react";

import ConceptLeft from "../components/ConceptLeft";
import ConceptPanel from "../components/ConceptPanel";
import Sidebar from "../components/Sidebar";
import { MetricNode } from "../lexical/MetricNode";
import { LexicalComposer } from "@lexical/react/LexicalComposer";
import { $getRoot, $createTextNode, $createParagraphNode } from "lexical";
import { useMetrics } from '../hooks/useMetrics';
import TopBar from "../components/TopBar";

const ConceptCentral: React.FC = () => {
    const { item } = useMetrics();

    const onError = (error: any) => {
        console.error(error);
    };

    const prepopulatedRichText = () => {
        const root = $getRoot();
        const paragraph = $createParagraphNode();
        const text = $createTextNode(item.logic || "");
        paragraph.append(text);
        root.append(paragraph);
    }

    const editorConfig = {
        namespace: "metrics-editor",
        onError: onError,
        nodes: [MetricNode],
        editorState: prepopulatedRichText,
    };

    return (
        <div className="App w-full h-screen relative flex">
            <LexicalComposer initialConfig={editorConfig}>
                <TopBar title="Concept Builder" />
                <Sidebar />
                <ConceptLeft />
                <ConceptPanel />
            </LexicalComposer>
        </div>
    );
};

export default ConceptCentral;
