import React, { useState, useEffect } from 'react';
import { useMetrics } from '../hooks/useMetrics';
import { useAuth } from '../hooks/useAuth';
import TextEditor from './TextEditor';
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import Buttons from "./Buttons";
import CodeContainer from './CodeContainer';
import TableContainer from "./TableContainer";
import Parameters from './Parameters';
import Loader from './Loader';
import { useToast } from "../hooks/useToast"
import { useLibrary } from '../hooks/useLibrary';
import { ReactComponent as StartIcon } from "../svg/start_icon.svg"
import { $getRoot, $createTextNode, $createParagraphNode } from "lexical";

interface TableColumn {
    COLUMN_NAME: string;
    DATA_TYPE: string;
}

const MetricPanel: React.FC = () => {
    const { type, item, items, setItem, setItems, addItem } = useMetrics();
    const [status, setStatus] = useState<string>("empty");
    const [sql, setSQL] = useState<string>("");
    const [editor] = useLexicalComposerContext();
    const [executionResult, setExecutionResult] = useState<string | null>(null);
    const [tableHead, setTableHead] = useState<string[]>([]);
    const [tableData, setTableData] = useState<any[]>([]);
    const [quotedTexts, setQuotedTexts] = useState<string[]>([]);
    const [params, setParams] = useState<string[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const toast = useToast();
    const { userProfile } = useAuth();
    const { library } = useLibrary();

    const handleGenerateSQL = async () => {
        setQuotedTexts([]);
        setParams([]);
        setTableHead([]);
        setTableData([]);
        const data = {
            "name": item.name,
            "desc": item.desc,
            "type": item.type,
            "biz_logic": editor.getRootElement()?.textContent || "",
            "logic_history": "",
            "answer_history": "",
        };

        const response = await fetch(`/api/create_sql?email=${userProfile?.email}`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(data),
        });

        const responseData = await response.json();
        console.log(responseData.data);
        setItem({ ...item, sql: responseData.data });
        const updatedItems = items.map(it => {
            if (it.id === item.id) {
                return { ...it, logic: item.logic || "" };
            }
            return it;
        });
        setItems(updatedItems);
        setStatus("completed");
    };

    const findParams = () => {
        const quoted = (editor.getRootElement()?.textContent || "").match(/{(.*?)}/g) || [];
        setQuotedTexts(quoted);
        console.log(quoted);

        if (quoted.length === 0) {
            executeSQL([]);
        }
        return;
    };

    const executeSQL = async (params: string[]) => {
        setLoading(true);
        const response = await fetch(`/api/execute_sql?email=${userProfile?.email}`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                "sql_statement": item.sql || "",
                "param_names": quotedTexts,
                "param_values": params,
            }),
        });

        const responseData = await response.json();
        console.log(responseData)
        setExecutionResult(JSON.stringify(responseData, null, 2));
        setTableHead(responseData.column_names);
        setTableData(responseData.records);
        setLoading(false);
    };

    const getButtonClass = (buttonType: string) => {
        return buttonState(buttonType)
            ? "rounded-md border border-black px-2 w-[120px] h-[50px] bg-gray-100"
            : "rounded-md border border-gray-300 px-2 w-[120px] h-[50px] text-gray-300";
    };

    const buttonState = (buttonType: string) => {
        if (buttonType === 'Delete') return true;
        if (buttonType === 'VerifiedState') return true;
        if (buttonType === 'Verified') return item.status !== 'Verified';

        const curItem = items.find(it => it.id === item.id && it.type === item.type);

        if (buttonType === 'Generate') return curItem?.logic !== item.logic;
        if (buttonType === 'Execute') return item.sql !== "";
        if (buttonType === 'Duplicate') return item.id > -1;
        return curItem !== item;
    };

    const handleButtonClick = async (buttonType: string) => {
        if (buttonType === "Dismiss") {
            const orig = items.filter(it => it.id === item.id && it.type === item.type)[0];
            setItem(orig);
            editor.update(() => {
                const root = $getRoot();
                root.clear();
                const paragraph = $createParagraphNode();
                const text = $createTextNode(orig.logic || "");
                paragraph.append(text);
                root.append(paragraph);
            });
            toast.success("Dismissed edits!");
            return;
        }

        if (buttonType === "Duplicate") {
            const copyItem = { ...item, name: "Copy of " + item.name };
            addItem(copyItem);
            return;
        }

        let status = "";
        if (buttonType === "Delete") status = "Deprecated";
        if (buttonType === "Draft") status = "Draft";
        if (buttonType === "Verified") {
            status = item.status !== "Verified" ? "Verified" : "Draft";
        }

        let newItem = {
            ...item,
            logic: editor.getRootElement()?.textContent || "",
            status,
        };

        const typeName = type.charAt(0).toLowerCase() + type.slice(1);
        const data = {
            [`${typeName}_id`]: newItem.id,
            [`${typeName}_name`]: newItem.name,
            "category_name": newItem.category || "",
            [`${typeName}_desc`]: newItem.desc || "",
            "sql_statement": newItem.sql || "",
            "modified_sql": newItem.modified_sql || "N",
            "logic": newItem.logic || "",
            "input_fields": newItem.input_fields || "",
            "output_fields": newItem.output_fields || "",
            "formula": newItem.formular || "",
            "status": newItem.status,
            "deprecated": status === "Deprecated" ? "D" : (newItem.deprecated || ""),
        };

        const response = await fetch(`/api/save_${typeName}?email=${userProfile?.email}&library_id=${library?.id}`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(data),
        });

        const responseData = await response.json();
        console.log(responseData);
        if (responseData.id) {
            newItem.id = responseData.id;
        };

        if (buttonType === "Delete") {
            const updatedItems = items.filter((currentItem) => currentItem.id !== item.id || currentItem.type !== item.type);
            setItems(updatedItems);
            setItem({ id: -1, name: "", type: "" });
            toast.success("Item deleted successfully!");
        } else {
            setItem(newItem);
            const updatedItems = items.map((currentItem) => (currentItem.id === item.id && currentItem.type === item.type) ? newItem : currentItem);
            setItems(updatedItems);
            toast.success("Saved successfully!");
        }
    };

    useEffect(() => {
        setQuotedTexts([]);
        setParams([]);
        setTableHead([]);
        setTableData([]);
    }, [item.id]);

    if (!item.type) {
        return (
            <div className="space-y-4 grow max-w-3xl flex flex-col items-center justify-center">
                <StartIcon className="w-150 h-150" />
                <div className='font-semibold text-center'>To get started, create a new concept or new metric,
                    or select an existing metric or concept to view and edit it</div>
                <div className='text-center'>Concepts are low level building blocks connecting directly to your data.</div>
                <div className='text-center'>Metrics string together Concepts and other Metrics describing more complex ideas that you want to measure.</div>
            </div>
        );
    }

    return (
        <div className="space-y-4 grow max-w-3xl pt-10">
            <Buttons type={type} handleButtonClick={handleButtonClick} buttonState={buttonState} />
            <div className="flex items-center border-2 border-gray-300 p-2">
                <label className="w-1/4">{type} Name:</label>
                <input className={`w-3/4 p-2 ${item.status === "Verified" && 'opacity-50'}`} type="text" placeholder="Enter name" value={item.name !== `Untitled ${type}` ? item.name : ''}
                    disabled={item.status === "Verified"}
                    onChange={(e) => { setItem({ ...item, name: e.target.value }) }} />
            </div>
            <div className="flex items-center border-2 border-gray-300 p-2">
                <label className="w-1/4">Summary Description:</label>
                <input className={`w-3/4 p-2 ${item.status === "Verified" && 'opacity-50'}`} type="text" value={item.desc || ''} placeholder="Enter description..."
                    disabled={item.status === "Verified"}
                    onChange={(e) => { setItem({ ...item, desc: e.target.value }) }} />
            </div>
            <label className="block text-lg font-bold m-2">Business Logic:</label>
            <div className="w-full flex items-center border-2 border-gray-300">
                <div className="flex-1 h-[180px] border-2 border-gray-300 m-4">
                    <TextEditor />
                </div>
                <button
                    className={`rounded-md border px-2 w-[100px] ${(!editor.getRootElement()?.textContent || !buttonState('Generate')) ? 'text-gray-500 border-gray-300 ' : 'bg-gray-100 border-black'}`}
                    disabled={!editor.getRootElement()?.textContent || !buttonState('Generate')}
                    onClick={() => {
                        handleGenerateSQL();
                        setStatus("loading");
                    }}
                >
                    Generate SQL
                </button>
                <div className="flex-1 w-1/2 h-[200px] border-2 border-gray-300 m-4">
                    <CodeContainer code={item.sql || ""} status={status} isUserModified={item.modified_sql || ""}
                        handleValueChange={(newSql: string) => { setItem({ ...item, sql: newSql, modified_sql: "Y" }) }}
                        disabled={item.status === "Verified"} />
                </div>
            </div>
            <div className="flex justify-center gap-2">
                <button onClick={() => handleButtonClick('Draft')}
                    className={getButtonClass('Draft')}
                    disabled={!buttonState('Draft')}>
                    Save
                </button>
                <button
                    className={getButtonClass('Execute')}
                    disabled={!buttonState('Execute')}
                    onClick={() => {
                        handleButtonClick('Draft');
                        findParams();
                    }}
                >
                    Save and Execute
                </button>
            </div>
            <div className="w-full border-2 border-gray-300 p-2">
                <Parameters quotedTexts={quotedTexts} params={params} setParams={setParams} executeSQL={executeSQL} />
                <div className='pt-2'>
                    {loading ? (
                        <Loader />
                    ) : (
                        <TableContainer input='' sql='' head={tableHead} data={tableData} id='' />
                    )}
                </div>
            </div>
        </div>
    )
};

export default MetricPanel;
