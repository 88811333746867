import React, { useEffect, useState } from 'react';
import { useMetrics } from '../hooks/useMetrics';
import { Item } from '../@types/common';
import { CheckCircleIcon, XMarkIcon, ChevronDownIcon, ChevronRightIcon } from '@heroicons/react/24/outline'
import { ReactComponent as EditIcon } from "../svg/EditIcon.svg"
import { ReactComponent as LoadIcon } from "../svg/LoadIcon.svg"
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { $getRoot, $createTextNode, $createParagraphNode } from "lexical";
import { Library } from '../@types/common';
import { useLibrary } from '../hooks/useLibrary';
import Loader from './Loader';

const ConceptLeft: React.FC = () => {
    const { item, setType, setItem, items, setItems, addNew } = useMetrics();
    const { library, setLibrary, libraries, setLibraries } = useLibrary();
    const [editor] = useLexicalComposerContext();
    const [libraryItems, setLibraryItems] = useState<Item[]>();
    const [expandedLibrary, setExpandedLibrary] = useState<number | null>(null);
    const [loading, setLoading] = useState(false);

    const fetchLibraries = async () => {
        const response = await fetch(`/api/get_libraries?company=${"ABC Healthcare"}`);
        const data = await response.json();
        console.log(data)
        const xolvedLibraries = data.libraries.filter((lib: Library) => lib.company === "ABC Healthcare");
        setLibraries(xolvedLibraries);
    };

    const fetchGlobalMetricsAndConcepts = async (libraryId?: number) => {
        const metricPromise = fetchItems("Metric", libraryId);
        const conceptPromise = fetchItems("Concept", libraryId);

        const [metrics, concepts] = await Promise.all([metricPromise, conceptPromise]);

        setItems([...metrics, ...concepts]);
    };

    const handleLibraryClick = async (libraryId: number) => {
        if (expandedLibrary === libraryId) {
            setExpandedLibrary(null);
            return;
        }

        setLoading(true);
        setExpandedLibrary(libraryId);

        const selectedLibrary = libraries?.find(lib => lib.id === libraryId);
        setLibrary(selectedLibrary || null);
        if (selectedLibrary && selectedLibrary.items) {
            setItems(selectedLibrary.items);
            setLoading(false);
            return;
        }

        const fetchData = async () => {
            const metricPromise = fetchItems("Metric", libraryId);
            const conceptPromise = fetchItems("Concept", libraryId);

            const [metrics, concepts] = await Promise.all([metricPromise, conceptPromise]);
            console.log(concepts)

            const updatedLibraries = libraries?.map(lib => {
                if (lib.id === libraryId) {
                    return {
                        ...lib,
                        items: [...metrics, ...concepts]
                    };
                }
                return lib;
            });
            setLibraries(updatedLibraries || []);
            setItems([...metrics, ...concepts]);
            setLoading(false);
        };

        fetchData();
    };

    const fetchItems = async (type: 'Metric' | 'Concept', libraryId?: number) => {
        const response = await fetch(`/api/get_${type.toLowerCase()}_list${libraryId ? `?library_id=${libraryId}` : ''}`);
        const data = await response.json();
        const items = data[type.toLowerCase() + 's'];
        const mappedItems = items.map((item: any[]): Item => ({
            type,
            id: item[0],
            category: item[1],
            name: item[2],
            desc: item[3],
            sql: item[4],
            modified_sql: item[5],
            logic: item[6],
            // formular: item[4],
            input_fields: item[7],
            output_fields: item[8],
            status: item[9],
            deprecated: item[10]
        }));
        return mappedItems;
    };

    const fetchConceptDetails = async (conceptId: number) => {
        try {
            const response = await fetch(`/api/get_concept_detail?concept_id=${conceptId}`);
            const data = await response.json();
            if (data.message === "success") {
                console.log(data)
                return {
                    attributes: data.attribute_list,
                    dimensions: data.dimension_list,
                    links: data.link_list,
                    filters: data.filter_list
                };
            }
        } catch (error) {
            console.error("Failed to fetch concept details:", error);
        }
        return null;
    };

    const handleConceptClick = async (concept: Item) => {
        setType('Concept');
        const details = await fetchConceptDetails(concept.id);
        if (details) {
            const newItem = { ...concept, ...details }
            setItem(newItem);
            const updatedItems = items.map((currentItem) => (currentItem.id === newItem.id && currentItem.type === newItem.type) ? newItem : currentItem);
            setItems(updatedItems);
        } else {
            setItem(concept);
        }
        editor.update(() => {
            const root = $getRoot();
            root.clear();
            const paragraph = $createParagraphNode();
            const text = $createTextNode(concept.logic || "");
            paragraph.append(text);
            root.append(paragraph);
        });
    };

    const addNewItem = (type: 'Metric' | 'Concept') => {
        addNew(type);
        editor.update(() => {
            const root = $getRoot();
            root.clear();
            const paragraph = $createParagraphNode();
            const text = $createTextNode("");
            paragraph.append(text);
            root.append(paragraph);
        });
    }

    const getStatusIcon = (status: string) => {
        if (status === "Deprecated") {
            return <XMarkIcon className="h-5 w-5 ml-1 inline-block" />
        } else if (status === "Verified") {
            return <CheckCircleIcon className="h-5 w-5 ml-1 inline-block" />
        }
        return null;
    }

    // useEffect(() => {
    //     const fetchData = async () => {
    //         const conceptPromise = fetchItems("Concept");

    //         const [concepts] = await Promise.all([conceptPromise]);

    //         setItems([...concepts]);
    //     };

    //     fetchData();
    // }, []);

    // useEffect(() => {
    //     fetchLibraries();
    //     // fetchGlobalMetricsAndConcepts();
    // }, []);

    return (
        <div className="h-screen min-w-[384px] w-[384px] px-4 pt-10">
            <div className="rounded-lg border-2 h-full w-full py-2 px-2 flex flex-col overflow-auto">
                {libraries?.map((library) => (
                    <div key={library.id}>
                        <button onClick={() => handleLibraryClick(library.id)}>
                            {library.name} {expandedLibrary === library.id ? <ChevronDownIcon className='h-5 w-5 inline' /> : <ChevronRightIcon className='h-5 w-5 inline' />}
                        </button>
                        {expandedLibrary === library.id && (
                            <>
                                {loading ? (
                                    <div className='m-2'><Loader /></div>
                                ) : (
                                    <>
                                        <div className="border-b mb-2 flex-grow ml-4">
                                            <div className="flex justify-between items-center mb-2">
                                                <div className='font-semibold underline'>Concepts</div>
                                                <button onClick={() => addNewItem('Concept')}>+ New Concept</button>
                                            </div>
                                            <div className="overflow-auto h-full max-h-[calc(50vh-4rem)]">
                                                {items?.filter(it => it.type === 'Concept').map((it, index) => (
                                                    <div className={`hover:cursor-pointer ${(it.id === item.id && item.type === "Concept") ? 'font-semibold' : ''}`} key={index} onClick={() => {
                                                        handleConceptClick(it);
                                                    }}>
                                                        {it.name}
                                                        {getStatusIcon(it.status!)}
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </>)}
                            </>)}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default ConceptLeft;
