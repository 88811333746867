import React, { createContext, useState, useContext } from "react";
import { Metric } from "../@types/common"
import { Concept } from "../@types/common"
import { Item } from "../@types/common"

interface MetricContextData {
    type: string;
    setType: (type: string) => void;
    item: Item;
    setItem: (metric: Item) => void;
    items: Item[];
    setItems: (metrics: Item[]) => void;
    addNew: (type: 'Metric' | 'Concept') => void;
    addItem: (item: Item) => void;
}

const MetricContext = createContext<MetricContextData | undefined>(undefined);

const useMetrics = () => {
    const context = useContext(MetricContext);
    if (!context) {
        throw new Error("useMetrics must be used within a MetricsProvider");
    }
    return context;
};

const MetricsProvider: React.FC<{ children: React.ReactNode }> = ({
    children,
}) => {
    const [type, setType] = useState('');
    const [item, setItem] = useState({ type: "", id: -1, name: "" });
    const [items, setItems] = useState<Item[]>([]);

    const [minId, setMinId] = useState(-1);

    const addNew = (type: 'Metric' | 'Concept') => {
        const newItem: Item = { type, id: minId, name: `Untitled ${type}` };
        setType(type);
        setItems([newItem, ...items]);
        setItem(newItem);
        setMinId(minId - 1);
    }

    const addItem = (item: Item) => {
        const copyItem = { ...item, id: minId }
        setType(type);
        setItems([copyItem, ...items]);
        setItem(copyItem);
        setMinId(minId - 1);
    }

    return (
        <MetricContext.Provider value={{ type, setType, item, setItem, items, setItems, addNew, addItem }}>
            {children}
        </MetricContext.Provider>
    );
};

export { MetricsProvider, useMetrics };
