import React, { createContext, useState, useContext } from "react";
import { Table } from "../@types/common"

interface TableDataContextData {
    table: Table;
    setTable: (table: Table) => void;
    tables: Table[];
    setTables: (tables: Table[]) => void;
}

const TableDataContext = createContext<TableDataContextData | undefined>(undefined);

const useTableData = () => {
    const context = useContext(TableDataContext);
    if (!context) {
        throw new Error("useTableData must be used within a TableDataProvider");
    }
    return context;
};

const TableDataProvider: React.FC<{ children: React.ReactNode }> = ({
    children,
}) => {
    const [table, setTable] = useState({ name: "" });
    const [tables, setTables] = useState<Table[]>([]);

    return (
        <TableDataContext.Provider value={{ table, setTable, tables, setTables }}>
            {children}
        </TableDataContext.Provider>
    );
};

export { TableDataProvider, useTableData };
