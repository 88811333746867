import React, { useState } from "react";
import {
  StarIcon,
  Cog8ToothIcon,
  UserCircleIcon,
} from "@heroicons/react/24/outline";
import { ReactComponent as Logo } from "../svg/X_icon.svg";
import { ReactComponent as Chart } from "../svg/chart_icon.svg";
import { ReactComponent as Chat } from "../svg/chat_icon.svg";
import { ReactComponent as Chemistry } from "../svg/chemistry_icon.svg";
import { ReactComponent as Shop } from "../svg/shop_icon.svg";
import siderbarBG from "../svg/left_nav_bg.svg"
import { NavLink, useLocation } from "react-router-dom";
import UserProfile from "./UserProfile";

const Sidebar: React.FC = () => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [curPage, setCurPage] = useState("Metric Central");
  const location = useLocation();

  const toggleSidebar = () => {
    setIsExpanded(prevState => !prevState);
  };

  const NavLinkWithIcon = (to: any, Icon: any, label: any) => (
    <NavLink
      to={to}
      className={`flex items-center rounded-md space-x-3 h-10 hover:bg-white hover:bg-opacity-10 ${location.pathname === to && 'bg-white bg-opacity-25'}`}
      onClick={(e) => { e.stopPropagation(); setCurPage(label) }}
    >
      <Icon className="h-6 w-6 min-w-[24px]" />
      <span className={`block transition-opacity duration-300 ${isExpanded ? "opacity-100 visible" : "opacity-0 invisible"}`}>{label}</span>
    </NavLink>
  );

  const OutsideLinkWithIcon = (to: any, Icon: any, label: any) => (
    <a
      href={to}
      className={`flex items-center rounded-md space-x-3 h-10 hover:bg-white hover:bg-opacity-10 ${curPage === label && 'bg-white bg-opacity-25'}`}
      onClick={e => e.stopPropagation()}
    >
      <Icon className="h-6 w-6 min-w-[24px]" />
      <span className={`block transition-opacity duration-300 ${isExpanded ? "opacity-100 visible" : "opacity-0 invisible"}`}>{label}</span>
    </a>
  );

  return (
    <>
      <div className={`${isExpanded && "w-[56px]"}`}></div>
      <nav
        className={`flex flex-col divide-y h-full text-white hover:cursor-pointer
  ${isExpanded ? "w-[240px] absolute top-0 left-0" : "w-[56px]"} transition-all duration-300 z-10 overflow-hidden`}
        style={{ backgroundImage: `url(${siderbarBG})`, backgroundSize: 'cover', height: '100%' }}
        onMouseEnter={toggleSidebar} onMouseLeave={toggleSidebar}
      >
        <div className="flex items-center space-x-3 p-2" id="sidebar_logo">
          <Logo className="h-10 w-10 min-w-[40px]" />
          <span className={`block transition-opacity duration-300 ${isExpanded ? "opacity-100 visible" : "opacity-0 invisible"}`}>Xolved</span>
        </div>
        <ul
          className="flex-1 flex flex-col p-4 justify-between space-y-4"
          id="sidebar_content"
        >
          <li className="">
            {OutsideLinkWithIcon("https://uguru.xolved.com", Chat, "Q&A")}
          </li>
          <li className="">
            {NavLinkWithIcon("/metriccentral", Chart, "Metric Central")}
          </li>
          <li className="">
            {NavLinkWithIcon("/conceptbuilder", Chemistry, "Concept Builder")}
          </li>
          <li className="">
            {NavLinkWithIcon("/metricstore", Shop, "Metric Store")}
          </li>
          <div className="grow"></div>
          <li className="flex items-center space-x-3 h-10">
            <Cog8ToothIcon className="h-6 w-6 min-w-[24px]" />
            <span className={`block transition-opacity duration-300 ${isExpanded ? "opacity-100 visible" : "opacity-0 invisible"}`}>Settings</span>
          </li>
          <li className="flex items-center space-x-3 h-10">
            <UserProfile className="h-6 w-6 min-w-[24px] relative" />
            <span className={`block transition-opacity duration-300 ${isExpanded ? "opacity-100 visible" : "opacity-0 invisible"}`}>Profile</span>
          </li>
        </ul>
      </nav>
    </>
  );
};

export default Sidebar;
