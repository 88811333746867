import React, { useState, useEffect } from 'react';
import Select from "react-select";
import { useMetrics } from '../hooks/useMetrics';
import { Item, Library } from '../@types/common';
import { CheckCircleIcon, XMarkIcon } from '@heroicons/react/24/outline'
import { ReactComponent as EditIcon } from "../svg/EditIcon.svg"
import { ReactComponent as LoadIcon } from "../svg/LoadIcon.svg"
import { ReactComponent as XolvedIcon } from "../svg/XolvedIcon.svg"
import Loader from './Loader';
import LibraryBox from './LibraryBox';
import { $getRoot, $createTextNode, $createParagraphNode } from "lexical";
import darkBG from "../svg/darkBG.svg"

const StorePanel: React.FC = () => {
    const { item, type, setType, setItem, items, setItems, addNew } = useMetrics();

    const [libraries, setLibraries] = useState<Library[]>([]);
    const [companyLibraries, setCompanyLibraries] = useState<Library[]>([]);
    const [libraryItems, setLibraryItems] = useState<Item[]>();
    const [selectedLibraryId, setSelectedLibraryId] = useState(-1);
    const [expandedLibrary, setExpandedLibrary] = useState<number | null>(null);
    const [loading, setLoading] = useState(false);

    const fetchLibraries = async () => {
        const response = await fetch(`/api/get_libraries?company=${"ABC Healthcare"}`);
        const data = await response.json();
        console.log(data)
        // const xolvedLibraries = data.libraries.filter((lib: Library) => lib.company === "Xolved Solutions");
        // setLibraries(xolvedLibraries);
        const companyLibraries = data.libraries.filter((lib: Library) => lib.company === "ABC Healthcare");
        setLibraries(companyLibraries);
    };

    const fetchGlobalMetricsAndConcepts = async (libraryId?: number) => {
        const metricPromise = fetchItems("Metric", libraryId);
        const conceptPromise = fetchItems("Concept", libraryId);

        const [metrics, concepts] = await Promise.all([metricPromise, conceptPromise]);

        setItems([...metrics, ...concepts]);
    };

    const handleLibraryClick = async (libraryId: number) => {
        if (expandedLibrary === libraryId) {
            setExpandedLibrary(null);
            return;
        }

        setLoading(true);
        setExpandedLibrary(libraryId);
        setSelectedLibraryId(libraryId);

        const selectedLibrary = libraries.find(lib => lib.id === libraryId);
        if (selectedLibrary && selectedLibrary.items) {
            setLibraryItems(selectedLibrary.items);
            setLoading(false);
            return;
        }

        const fetchData = async () => {
            const metricPromise = fetchItems("Metric", libraryId);
            const conceptPromise = fetchItems("Concept", libraryId);

            const [metrics, concepts] = await Promise.all([metricPromise, conceptPromise]);

            const updatedLibraries = libraries.map(lib => {
                if (lib.id === libraryId) {
                    return {
                        ...lib,
                        items: [...metrics, ...concepts]
                    };
                }
                return lib;
            });
            setLibraries(updatedLibraries);
            setLibraryItems([...metrics, ...concepts]);
            setLoading(false);
        };

        fetchData();
    };

    const fetchItems = async (type: 'Metric' | 'Concept', libraryId?: number) => {
        const response = await fetch(`/api/get_${type.toLowerCase()}_list${libraryId ? `?library_id=${libraryId}` : ''}`);
        const data = await response.json();
        console.log(data);
        const items = data[type.toLowerCase() + 's'];
        const mappedItems = items.map((item: any[]): Item => ({
            type,
            id: item[0],
            category: item[1],
            name: item[2],
            desc: item[3],
            sql: item[4],
            modified_sql: item[5],
            logic: item[6],
            // formular: item[4],
            input_fields: item[7],
            output_fields: item[8],
            status: item[9],
            deprecated: item[10]
        }));
        return mappedItems;
    };

    const addNewItem = (type: 'Metric' | 'Concept') => {
        addNew(type);
    }

    const getStatusIcon = (status: string) => {
        if (status === "Deprecated") {
            return <XMarkIcon className="h-5 w-5 ml-1 inline-block" />
        } else if (status === "Verified") {
            return <CheckCircleIcon className="h-5 w-5 ml-1 inline-block" />
        }
        return null;
    }

    useEffect(() => {
        fetchLibraries();
        fetchGlobalMetricsAndConcepts();
    }, []);

    return (
        <div className="text-white w-full px-4 flex flex-col justify-between overflow-auto"
            style={{ backgroundImage: `url(${darkBG})`, backgroundSize: 'cover', minHeight: '100vh' }}>
            <div className='flex m-2 items-center'>
                <XolvedIcon className='h-10 w-10' />
                <span className='text-4xl font-bold'>Xolved</span>
                <div className='px-4 font-semibold'>Access the right metrics to understand your healthcare operations </div>
            </div>
            <div className='px-4'>1) Subscribe to just one or as many metric libraries as you need. </div>
            <div className='px-4'>2) Quickly connect to your institutions’ data sources using our tools and AI assistance. </div>
            <div className='px-4'>3) Extend and customize metrics to fit your unique needs. </div>
            <div className="h-full w-full py-2 px-2 flex flex-col">
                {libraries.map((library) => (
                    <LibraryBox
                        key={library.id}
                        library={library}
                        expanded={expandedLibrary === library.id}
                        onLibraryClick={handleLibraryClick}
                        onItemSelect={(type, selected) => {
                            setType(type);
                            setItem(selected);
                        }}
                        addItem={addNewItem}
                        currentItemSelected={item}
                    />
                ))}
            </div>
        </div>
    );
};

export default StorePanel;
