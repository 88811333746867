import React from "react";

import MetricLeft from "../components/MetricLeft"
import MetricPanel from "../components/MetricPanel";
import ConceptPanel from "../components/ConceptPanel";
import MetricView from "../components/MetricView";
import Sidebar from "../components/Sidebar";
import { MetricNode } from "../lexical/MetricNode";
import { LexicalComposer } from "@lexical/react/LexicalComposer";
import { $getRoot, $createTextNode, $createParagraphNode } from "lexical";
import { useMetrics } from '../hooks/useMetrics';
import TopBar from "../components/TopBar";

const MetricCentral: React.FC = () => {
    const { item, type } = useMetrics();

    const onError = (error: any) => {
        console.error(error);
    };

    const prepopulatedRichText = () => {
        const root = $getRoot();
        const paragraph = $createParagraphNode();
        const text = $createTextNode(item.logic || "");
        paragraph.append(text);
        root.append(paragraph);
    }

    const editorConfig = {
        namespace: "metrics-editor",
        onError: onError,
        nodes: [MetricNode],
        editorState: prepopulatedRichText,
    };

    const renderPanel = () => {
        if (type === "Metric") {
            return <MetricPanel />;
        } else if (type === "Concept") {
            return <ConceptPanel />;
        }
        return <MetricPanel />;
    };

    return (
        <div className="App w-full h-screen relative flex">
            <LexicalComposer initialConfig={editorConfig}>
                <TopBar title="Metric Central" />
                <Sidebar />
                <MetricLeft />
                {renderPanel()}
            </LexicalComposer>
        </div>
    );
};

export default MetricCentral;
