import React, { useEffect, useState } from 'react';
import { useTableData } from '../hooks/useTableData';
import { CheckCircleIcon, XMarkIcon } from '@heroicons/react/24/outline'
import { ReactComponent as EditIcon } from "../svg/EditIcon.svg"
import { ReactComponent as UnreviewedIcon } from "../svg/UnreviewedIcon.svg"
import { ReactComponent as LoadIcon } from "../svg/LoadIcon.svg"

const LeftPanel: React.FC = () => {
    const { table, setTable, tables, setTables } = useTableData();

    const fetchTableAnnotations = async () => {
        const response = await fetch(`/api/getalltableannotation`);
        const responseData = await response.json();
        const annotations = responseData.data;

        setTables(annotations.map((annotation: any[]) => ({
            name: annotation[0] + "." + annotation[1],
            desc: annotation[2],
            status: annotation[3],
        })));
    };

    useEffect(() => {
        fetchTableAnnotations();
    }, []);

    const getStatusIcon = (status: string) => {
        if (status === "Deprecated") {
            return <XMarkIcon className="h-5 w-5 ml-1 inline-block" />
        } else if (status === "Draft") {
            return <LoadIcon className="h-5 w-5 ml-1 inline-block" />
        } else if (status === "Verified") {
            return <CheckCircleIcon className="h-5 w-5 ml-1 inline-block" />
        }
        return <UnreviewedIcon className="h-5 w-5 ml-1 inline-block" />;
    }

    return (
        <div className="h-screen min-w-[384px] w-[384px] px-4">
            <div className="border border-black bg-gray-100 h-full w-full py-2 px-2 flex flex-col">
                <div className="border-b border-black mb-2">Server Database</div>
                <div className="overflow-auto flex-grow">
                    {tables.map((tb, index) => (
                        <div className={`hover:cursor-pointer ${table.name === tb.name && "font-semibold"} ${tb.status === "Deprecated" && "text-gray-500"}`}
                            key={index} onClick={() => {
                                setTable(tb);
                            }}>
                            {tb.name}
                            {getStatusIcon(tb.status || "")}
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default LeftPanel;
