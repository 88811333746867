import React, { useState, useEffect } from 'react';
import DatePicker from "react-datepicker";
import { useTableData } from '../hooks/useTableData';
import { CheckCircleIcon, XMarkIcon } from '@heroicons/react/24/outline'
import { ReactComponent as EditIcon } from "../svg/EditIcon.svg"
import { ReactComponent as LoadIcon } from "../svg/LoadIcon.svg"
import "react-datepicker/dist/react-datepicker.css";

interface ParametersProps {
    quotedTexts: string[];
    params: string[];
    setParams: (params: string[]) => void;
    executeSQL: (params: string[]) => void;
}

const Parameters: React.FC<ParametersProps> = ({ quotedTexts, params, setParams, executeSQL }) => {
    const [startDates, setStartDates] = useState<(Date | null)[]>([]);
    const [endDates, setEndDates] = useState<(Date | null)[]>([]);
    const [buttonDisabled, setButtonDisabled] = useState(true);

    useEffect(() => {
        const initialDates = new Array(quotedTexts.length).fill(null);
        setStartDates(initialDates);
        setEndDates(initialDates);
    }, [quotedTexts]);

    useEffect(() => {
        setButtonDisabled(params.length !== quotedTexts.length);
    }, [quotedTexts, params]);

    return (
        <div>
            {quotedTexts.length > 0 && (
                <div className="flex flex-col border-2 border-gray-300 p-2">
                    <div>Please provide the following information for this query:</div>
                    {quotedTexts.map((text, index) => {
                        const isDate = ["date", "time", "period"].some(keyword => text.toLowerCase().includes(keyword));
                        return (
                            <div key={index} className="flex items-center space-x-2">
                                <div>{`${text}:`}</div>
                                {isDate ? (
                                    <>
                                        <DatePicker selected={startDates[index]} placeholderText="mm/dd/yyyy"
                                            onChange={(date) => {
                                                const newStartDates = [...startDates];
                                                newStartDates[index] = date;
                                                setStartDates(newStartDates);

                                                const newParameters = [...params];
                                                newParameters[index] = `${date?.toLocaleDateString() || ""},${params[index]?.split(",")[1] || ""}`;
                                                setParams(newParameters);
                                            }} />
                                        <span>to</span>
                                        <DatePicker selected={endDates[index]} placeholderText="mm/dd/yyyy"
                                            onChange={(date) => {
                                                const newEndDates = [...endDates];
                                                newEndDates[index] = date;
                                                setEndDates(newEndDates);

                                                const newParameters = [...params];
                                                newParameters[index] = `${params[index]?.split(",")[0] || ""},${date?.toLocaleDateString() || ""}`;
                                                setParams(newParameters);
                                                console.log(params)
                                            }} />
                                    </>
                                ) : (
                                    <input
                                        className="w-3/4 p-2"
                                        type={"text"}
                                        placeholder={`Enter ${text}`}
                                        onChange={(e) => {
                                            const newParameters = [...params];
                                            newParameters[index] = e.target.value;
                                            setParams(newParameters);
                                        }}
                                    />
                                )}
                            </div>
                        )
                    })}
                    <button
                        className={`rounded-md border px-2 mt-2 ${buttonDisabled ? 'border-gray-300 text-gray-300' : 'bg-gray-100 border-black'}`}
                        disabled={buttonDisabled}
                        onClick={() => executeSQL(params)}
                    >
                        Execute with Parameters
                    </button>
                </div>
            )}
        </div>
    );
};

export default Parameters;
