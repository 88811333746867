import React from "react";

import LeftPanel from "../components/LeftPanel"
import MainPanel from "../components/MainPanel";
import Sidebar from "../components/Sidebar";

const DataDictionary: React.FC = () => {
    return (
        <div className="App w-full h-screen relative flex">
            <Sidebar />
            <LeftPanel />
            <MainPanel />
        </div>
    );
};

export default DataDictionary;