import React, { useState } from "react";
import { Item, Attribute, Detail } from "../@types/common";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/24/solid";
import { XMarkIcon, InformationCircleIcon, TrashIcon } from "@heroicons/react/24/outline";
import Select from "react-select";


type ItemType = "Attribute" | "Link" | "Filter" | "Dimension";

interface Props {
    data: Attribute;
    type: ItemType;
    item: Item;
    setItem: (item: Item) => void;
    isExpanded: boolean;
    onExpand: () => void;
    onClose: () => void;
}

const AttributeBox: React.FC<Props> = ({ data, type, item, setItem, isExpanded, onExpand, onClose }) => {
    // const [isExpanded, setIsExpanded] = useState(false);
    const operators = [
        { value: 'is mempty', label: 'Is empty' },
        { value: 'is not empty', label: 'Is not empty' },
        { value: 'text contains', label: 'Text contains' },
        { value: 'text does not contain', label: 'Text does not contain' },
        { value: 'text starts with', label: 'Text starts with' },
        { value: 'text ends with', label: 'Text ends with' },
        { value: 'text is exactly', label: 'Text is exactly' },
        { value: 'greater than', label: 'Greater than' },
        { value: 'greater than or equal to', label: 'Greater than or equal to' },
        { value: 'less than', label: 'Less than' },
        { value: 'less than or equal to', label: 'Less than or equal to' },
        { value: 'is equal to', label: 'Is equal to' },
        { value: 'is not equal to', label: 'Is not equal to' },
        { value: 'is between', label: 'Is between' },
        { value: 'is not between', label: 'Is not between' },
    ];

    const dataConnectionOptions = item.output_fields?.split(",").map(field => ({
        value: field.trim(),
        label: field.trim(),
    }));

    const updateItem = (key: string, value: any) => {
        const updatedData = { ...data, [key]: value };
        const property = (type.toLowerCase() + "s") as keyof typeof item;
        const existingList = item[property] as (Attribute | Detail)[];
        const updatedList = existingList.map(currentData =>
            currentData.id === data.id ? updatedData : currentData
        );
        setItem({ ...item, [property]: updatedList });
    };

    const deleteItem = () => {
        const property = (type.toLowerCase() + "s") as keyof typeof item;
        const existingList = item[property] as (Attribute | Detail)[];
        const updatedList = existingList.filter(currentData => currentData.id !== data.id);
        setItem({ ...item, [property]: updatedList });
    };

    const updateMapper = (index: number, key: any, value: string) => {
        const updatedMappers = [...(data.mappers || [])];

        updatedMappers[index] = {
            ...updatedMappers[index],
            [key]: value
        };

        updateItem('mappers', updatedMappers);
    };

    const addMapper = () => {
        const newMapper = {
            operator: '',
            ori_value: '',
            map_value: ''
        };
        const updatedMappers = [...(data.mappers || []), newMapper];
        updateItem('mappers', updatedMappers);
    };

    const deleteMapper = (indexToRemove: number) => {
        const updatedMappers = data.mappers?.filter((_, index) => index !== indexToRemove) || [];
        updateItem('mappers', updatedMappers);
    };


    return (
        <div className="flex space-x-2">
            <div className="border p-2 mt-2 w-full">
                <div className="flex justify-between">
                    <div className="w-full">
                        {isExpanded ? (
                            <div className="grid grid-cols-1 gap-2">
                                <div className="flex items-center space-x-2 pr-2">
                                    <label className="text-sm font-medium text-gray-700">Name:</label>
                                    <input
                                        type="text"
                                        placeholder="Enter the name"
                                        value={data.name}
                                        onClick={e => e.stopPropagation()}
                                        onChange={(e) => {
                                            updateItem('name', e.target.value);
                                        }}
                                        className="border p-1 flex-grow w-full "
                                    />
                                </div>
                                <div className="flex items-center space-x-2 pr-2">
                                    <label className="text-sm font-medium text-gray-700">Desc:</label>
                                    <input
                                        type="text"
                                        placeholder="Describe the attribute"
                                        value={data.desc}
                                        onClick={e => e.stopPropagation()}
                                        onChange={(e) => {
                                            updateItem('desc', e.target.value);
                                        }}
                                        className="border p-1 flex-grow w-full "
                                    />
                                </div>
                                <div className="flex items-center mt-2">
                                    <div className="">Data Mapping Rules:</div>
                                    {data.mappers?.length === 0 && <button
                                        className="ml-2 text-sm px-2 font-semibold"
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            addMapper();
                                        }}
                                    >
                                        + Add Rule
                                    </button>}
                                </div>
                                {data.mappers && data.mappers.map((mapper, index) => (
                                    <div key={index} className="ml-10 flex items-center space-x-2">
                                        <label className="text-sm font-medium text-gray-700">If</label>
                                        <div className="w-1/4" onClick={e => e.stopPropagation()}>
                                            <Select
                                                placeholder="Choose operator"
                                                value={operators.find(option => option.value === mapper.operator)}
                                                onChange={(selectedOption) => {
                                                    updateMapper(index, 'operator', selectedOption?.value || "");
                                                }}
                                                className="text-xs"
                                                options={operators}
                                                isSearchable={false}
                                            />
                                        </div>
                                        <input
                                            type="text"
                                            placeholder="value of data"
                                            value={mapper.ori_value}
                                            onClick={e => e.stopPropagation()}
                                            onChange={(e) => {
                                                updateMapper(index, 'ori_value', e.target.value);
                                            }}
                                            className="border p-1 w-1/4"
                                        />
                                        <label className="text-sm font-medium text-gray-700">then treat as</label>
                                        <input
                                            type="text"
                                            value={mapper.map_value}
                                            onClick={e => e.stopPropagation()}
                                            onChange={(e) => {
                                                updateMapper(index, 'map_value', e.target.value);
                                            }}
                                            className="border p-1 w-1/4"
                                        />
                                        <TrashIcon
                                            className="h-5 w-5 hover:text-red-600 hover:cursor-pointer ml-2"
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                deleteMapper(index);
                                            }}
                                        />
                                    </div>
                                ))}
                                {data.mappers?.length > 0 && <button
                                    className="flex ml-[161px] text-sm justify-left font-semibold"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        addMapper();
                                    }}
                                >
                                    + Add Rule
                                </button>}
                                <div className="flex items-center pr-2">
                                    <div className="flex items-center space-x-2">
                                        <label className="text-sm font-medium text-gray-700">Linker:</label>
                                        <input
                                            type="checkbox"
                                            checked={data.linker === 'Y'}
                                            onClick={e => e.stopPropagation()}
                                            onChange={() => {
                                                const newLinkerValue = data.linker === 'Y' ? 'N' : 'Y';
                                                updateItem('linker', newLinkerValue);
                                            }}
                                        />
                                    </div>
                                    <InformationCircleIcon className="ml-2 h-4 w-4" />
                                    <div className="text-xs text-gray-600 mr-2">Data linkers are attributes which can be used to link to other concepts (typically an ID field)</div>
                                </div>
                                <div className="flex items-center">
                                    <label className="text-sm font-medium text-gray-700 mr-2">Data connection:</label>
                                    <div className="w-1/4" onClick={e => e.stopPropagation()}>
                                        <Select
                                            placeholder="Identify the data"
                                            value={dataConnectionOptions?.find(option => option.value === data.data_connection)}
                                            onChange={(selectedOption) => {
                                                updateItem('data_connection', selectedOption?.value || "");
                                            }}
                                            className="text-xs"
                                            options={dataConnectionOptions}
                                            isSearchable={false}
                                        />
                                    </div>
                                    <InformationCircleIcon className="ml-2 h-4 w-4" />
                                    <div className="text-xs text-gray-600 mr-2 w-1/2">Data field options are derived from the SELECT statement of the SQL provided above</div>
                                </div>
                            </div>
                        ) : (
                            <div className="hover:cursor-pointer" onClick={isExpanded ? onClose : onExpand}>{data.name}</div>
                        )}
                    </div>
                    <div className="flex items-start space-x-2 hover:cursor-pointer" onClick={isExpanded ? onClose : onExpand}>
                        {isExpanded ? (
                            <ChevronUpIcon className="h-5 w-5 text-gray-500" />
                        ) : (
                            <ChevronDownIcon className="h-5 w-5 text-gray-500" />
                        )}
                    </div>
                </div>
            </div>
            <TrashIcon
                className="h-5 w-5 mt-4 hover:text-red-600 hover:cursor-pointer"
                onClick={(e) => {
                    e.stopPropagation();
                    deleteItem();
                }}
            />
        </div>
    );
};

export default AttributeBox;
