import React, { useEffect, useState, useCallback, useMemo } from "react";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import {
  MenuOption,
  LexicalTypeaheadMenuPlugin,
  MenuTextMatch,
} from "@lexical/react/LexicalTypeaheadMenuPlugin";
import { TextNode } from "lexical";
import { $createMetricNode } from "../lexical/MetricNode";
import ReactDOM from "react-dom";
import { CheckCircleIcon } from '@heroicons/react/24/outline'
import { Item } from "../@types/common";

const metricsCache = new Map();

const metricsSearch = (
  query: string,
  metricNames: string[],
  conceptNames: string[],
  callback: (results: Array<string>) => void,
) => {
  setTimeout(() => {
    const metricResults = metricNames.filter((metric) =>
      metric.toLowerCase().includes(query.toLowerCase())
    );
    const conceptResults = conceptNames
      .filter((name) => name.toLowerCase().includes(query.toLowerCase()))
    const results = [...metricResults, ...conceptResults].sort((a, b) =>
      a.localeCompare(b)
    );
    callback(results);
  }, 500);
};

const useMetricLookupService = (queryString: string | null, metricNames: string[], conceptNames: string[]) => {
  const [results, setResults] = useState<Array<string>>([]);

  useEffect(() => {
    if (queryString === null) {
      setResults([]);
      return;
    }

    const cachedResults = metricsCache.get(queryString);
    if (cachedResults === null) {
      return;
    } else if (cachedResults !== undefined) {
      setResults(cachedResults);
      return;
    }

    metricsCache.set(queryString, null);
    metricsSearch(queryString, metricNames, conceptNames, (results) => {
      metricsCache.set(queryString, results);
      setResults(results);
    });
  }, [queryString]);

  return results;
};

const MetricRegex = /(^|\s)(\[((?:[a-zA-z0-9\s]){0,20}))$/;

const getPossibleQueryMatch = (text: string): MenuTextMatch | null => {
  let match = MetricRegex.exec(text);
  if (match) {
    const maybeLeadingWhitespace = match[1];
    const matchingString = match[3];
    return {
      leadOffset: match.index + maybeLeadingWhitespace.length,
      matchingString,
      replaceableString: match[2],
    }
  }

  return null;
}

class MetricTypeaheadOption extends MenuOption {
  name: string;
  status: string;
  type: string;
  description: string;

  constructor(name: string, status: string, type: string, description: string) {
    super(name);
    this.name = name;
    this.status = status;
    this.type = type;
    this.description = description;
  }
}

interface MetricsAutoCompletePluginProps {
  metrics: Item[],
  concepts: Item[],
}

const MetricsAutoCompletePlugin: React.FC<MetricsAutoCompletePluginProps> = ({ metrics, concepts }) => {
  const [editor] = useLexicalComposerContext();

  const [queryString, setQueryString] = React.useState<string | null>(null);

  const [activeTab, setActiveTab] = React.useState<'All' | 'Metrics' | 'Concepts'>('All');

  const metricNames = metrics.map(metric => metric.name);
  const metricStatuses = metrics.map(metric => metric.status);

  const conceptNames = concepts.map(concept => concept.name);
  const conceptStatuses = concepts.map(concept => concept.status);

  const results = useMetricLookupService(queryString, metricNames, conceptNames);

  const options = useMemo(() => {
    let filteredResults = results;

    if (activeTab === 'Metrics') {
      filteredResults = results.filter(name => metricNames.includes(name));
    } else if (activeTab === 'Concepts') {
      filteredResults = results.filter(name => conceptNames.includes(name));
    }

    return filteredResults.map((result) => {
      let object = metrics.find(item => item.name === result);
      let status = "";
      let type = "";
      let description = "";

      if (object) {
        status = object.status!;
        type = object.type;
        description = object.desc!;
      } else {
        object = concepts.find(item => item.name === result);
        if (object) {
          status = object.status!;
          type = object.type;
          description = object.desc!;
        }
      }

      return new MetricTypeaheadOption(result, status!, type, description);
    });
  }, [results, activeTab]);


  const onOptionSelected = useCallback(
    (selectedOption: MetricTypeaheadOption, nodeToReplace: TextNode | null, closeMenu: () => void) => {
      editor.update(() => {
        const isMetric = metricNames.includes(selectedOption.name);
        const color = isMetric ? "text-purple-600" : "text-blue-600";
        console.log(selectedOption)
        const metricNode = $createMetricNode(`[${selectedOption.name}]`, color, selectedOption.status, selectedOption.type, selectedOption.description);
        if (nodeToReplace) {
          nodeToReplace.replace(metricNode);
        }
        metricNode.select();
        closeMenu();
      });
    },
    [editor]
  );

  const checkForMatch = useCallback(
    (text: string) => {
      return getPossibleQueryMatch(text);
    }, [editor]);


  return (
    <LexicalTypeaheadMenuPlugin<MetricTypeaheadOption>
      onQueryChange={setQueryString}
      onSelectOption={onOptionSelected}
      triggerFn={checkForMatch}
      options={options}
      menuRenderFn={(anchorElementRef, { selectedIndex, selectOptionAndCleanUp, setHighlightedIndex }) =>
        anchorElementRef.current && ReactDOM.createPortal(
          <div className="mt-5 bg-white shadow-md rounded-md w-[250px] flex flex-col">
            <div className="flex">
              <button
                className={`flex-1 ${activeTab === 'All' ? 'bg-gray-200' : ''}`}
                onMouseDown={event => {
                  event.preventDefault();
                  setActiveTab('All');
                  console.log(options)
                }}
              >
                All
              </button>
              <button
                className={`flex-1 ${activeTab === 'Metrics' ? 'bg-gray-200' : ''}`}
                onMouseDown={event => {
                  event.preventDefault();
                  setActiveTab('Metrics');
                }}
              >
                Metrics
              </button>
              <button
                className={`flex-1 ${activeTab === 'Concepts' ? 'bg-gray-200' : ''}`}
                onMouseDown={event => {
                  event.preventDefault();
                  setActiveTab('Concepts');
                }}
              >
                Concepts
              </button>
            </div>
            {options.map((option, index) => (
              <div
                key={option.key}
                className={`px-4 py-2 cursor-pointer hover:bg-gray-100 w-full ${index === selectedIndex ? "bg-gray-100" : ""
                  }`}
                onClick={() => {
                  setHighlightedIndex(index);
                  selectOptionAndCleanUp(option)
                }}
                onMouseEnter={() => setHighlightedIndex(index)}
              >
                {option.name}
                {option.status === "Verified" && <CheckCircleIcon className="h-5 w-5 ml-1 inline-block" />}
              </div>
            ))}
          </div>
          , anchorElementRef.current)
      }
    />
  );
};

export default MetricsAutoCompletePlugin;
