import React, { createContext, useState, useContext, useEffect } from "react";
import { GoogleOAuthProvider } from "@react-oauth/google";

export interface UserProfile {
  id: string;
  email: string;
  name: string;
  picture: string;
  expiresAt: number;
}

interface AuthContextData {
  isAuthenticated: boolean;
  userProfile: UserProfile | null;
  setUserProfile: (profile: UserProfile | null) => void;
}

const AuthContext = createContext<AuthContextData | undefined>(undefined);

const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};

const AuthProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [userProfile, setUserProfile] = useState<UserProfile | null>(() => {
    const storedProfileString = localStorage.getItem("userProfile");
    if (!storedProfileString) {
      return null;
    }
    const storedProfile = JSON.parse(storedProfileString) as UserProfile;
    if (storedProfile.expiresAt < Date.now() / 1000) {
      localStorage.removeItem("userProfile");
      return null;
    }
    return storedProfile;
  });

  const isAuthenticated = userProfile !== null;

  useEffect(() => {
    if (userProfile) {
      localStorage.setItem("userProfile", JSON.stringify(userProfile));
    } else {
      localStorage.removeItem("userProfile");
    }
  }, [userProfile]);

  return (
    <GoogleOAuthProvider clientId="702059312908-9caa5ren1aqa25o77r1uf436hehd7tre.apps.googleusercontent.com">
      <AuthContext.Provider
        value={{ isAuthenticated, userProfile, setUserProfile }}
      >
        {children}
      </AuthContext.Provider>
    </GoogleOAuthProvider>
  );
};

export { AuthProvider, useAuth };
