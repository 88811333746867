import React from "react";
import { Table } from "flowbite-react";
import * as XLSX from "xlsx";

interface TableContainerProps {
  input: string;
  sql: string;
  head: string[];
  data: any[];
  id: string;
}

const TableContainer: React.FC<TableContainerProps> = ({ input, sql, head, data, id }) => {
  const maxRows = 5;
  const maxColumns = 5;
  const slicedData = data.slice(0, maxRows).map(row => row.slice(0, maxColumns));
  const slicedHead = head.slice(0, maxColumns);

  const hiddenColumns = head.slice(maxColumns);
  const hiddenColumnsCount = hiddenColumns.length;
  const hiddenRowsCount = data.length - maxRows;

  const isNumeric = (value: any) => {
    if (typeof value !== 'string') return false // we only process strings!
    return !isNaN(value.replace(/[$,]/g, '') as unknown as number) && !isNaN(parseFloat(value.replace(/[$,]/g, '')));
  }


  const exportToExcel = () => {
    if (head && data) {
      const dataWithHeaders = data.map((row) => {
        return head?.reduce((obj, header, index) => {
          return { ...obj, [header]: row[index] };
        }, {});
      });

      if (dataWithHeaders) {
        const ws = XLSX.utils.json_to_sheet(dataWithHeaders);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Query Data");

        const messagesToExport = [[input], [sql]];
        const ws2 = XLSX.utils.aoa_to_sheet(messagesToExport);
        XLSX.utils.book_append_sheet(wb, ws2, "SQL");

        XLSX.writeFile(wb, "table_data.xlsx");
      }
    }
  };

  return (
    <>
      <div className="table-container overflow-auto">
        <Table className="pt-2 overflow-y-auto" striped>
          <Table.Head>
            {slicedHead.map((headItem, index) => (
              <Table.HeadCell key={`${id}-th-${index}`}>
                {headItem}
              </Table.HeadCell>
            ))}
          </Table.Head>
          <Table.Body>
            {slicedData.map((row, index) => (
              <Table.Row key={`${id}-tr-${index}`}>
                {row.map((item: any, index: number) => (
                  <Table.Cell key={`${id}-tr-${index}-tc-${index}`}
                    className={isNumeric(item) ? "text-right" : "text-left"}>
                    {item}
                  </Table.Cell>
                ))}
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </div>
      <div className="text-left text-gray-500 pt-1">
        Showing {Math.min(slicedData.length, maxRows)} out of {data.length} rows
        {(hiddenRowsCount > 0 || hiddenColumnsCount > 0) && (
          <>
            {hiddenColumnsCount > 0 && (
              <>
                <span style={{ fontSize: '0.3em', verticalAlign: 'middle', margin: '0.5em' }}> ● </span> Not showing {hiddenColumnsCount} columns of data: "{hiddenColumns.join('", "')}"
              </>
            )}
            <span style={{ fontSize: '0.3em', verticalAlign: 'middle', margin: '0.5em' }}> ● </span>
            <span
              onClick={exportToExcel}
              className=" text-blue-600 hover:text-blue-800 hover:cursor-pointer visited:text-purple-600"
            >
              Export entire table of data to Excel
            </span>
          </>
        )}
      </div>

    </>
  );
};

export default TableContainer;
