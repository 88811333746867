import React, { useEffect } from "react";
import { RichTextPlugin } from "@lexical/react/LexicalRichTextPlugin";
import LexicalErrorBoundary from "@lexical/react/LexicalErrorBoundary";
import { ContentEditable } from "@lexical/react/LexicalContentEditable";
import { OnChangePlugin } from '@lexical/react/LexicalOnChangePlugin';
import MetricsAutoCompletePlugin from "./MetricsAutoCompletePlugin";
import ConceptsAutoCompletePlugin from "./ConceptsAutoCompletePlugin";
import QuotedTextPlugin from "./QuotedTextPlugin";
import BraceTextPlugin from "./BraceTextPlugin";
import BracketTextPlugin from "./BracketTextPlugin";
import { AutoFocusPlugin } from "@lexical/react/LexicalAutoFocusPlugin";
import { useMetrics } from "../hooks/useMetrics";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";

const TextEditor: React.FC = () => {
  const { type, item, items, setItem } = useMetrics();
  const [editor] = useLexicalComposerContext();

  const metrics = items.filter(it => it.type === 'Metric');
  const concepts = items.filter(it => it.type === 'Concept');

  const metricNames = metrics.map(metric => metric.name);
  const metricStatuses = metrics.map(metric => metric.status);

  const conceptNames = concepts.map(concept => concept.name);
  const conceptStatuses = concepts.map(concept => concept.status);

  const onChange = () => {
    const text = editor.getRootElement()?.textContent || item.logic;
    if (item.logic !== text) {
      setItem({ ...item, logic: text || "" })
    }
  }

  useEffect(() => {
    editor.setEditable(item.status !== "Verified");
  }, [item.status]);

  return (
    <div className="relative mx-3 h-full overflow-auto">
      <RichTextPlugin
        contentEditable={
          <ContentEditable className={`relative outline-none px-2 py-3 h-full ${item.status === "Verified" && "opacity-50"}`} />
        }
        placeholder={
          type === "Metric" ?
            <div className="text-placeholder absolute top-3 left-2 text-gray-600 inline-block overflow-hidden text-ellipsis pointer-events-none">
              <span>Describe the detailed business logic for this metric.<br /></span>

              <span>Type: </span>
              <span className="">[ ]</span>
              <span> to reference other metrics or concepts, </span>
              <span className="text-green-600">{"{ }"}</span>
              <span> to identify inputs to be specified at execution time<br /></span>

              <span>E.g. Total of all </span>
              <span className="text-purple-600">[Acute Admissions]</span>
              <span> for </span>
              <span className="text-blue-600">[Patient age]</span>
              <span> of at least 2 years old in specified </span>
              <span className="text-green-600">{"{Location}"}</span>

            </div>
            :
            <div className="text-placeholder absolute top-3 left-2 text-gray-600 inline-block overflow-hidden text-ellipsis pointer-events-none">
              Describe what data is used for this concept<br />

              <span className="font-bold text-red-600">NOTE:</span>
              <span> Concepts cannot reference other concepts or metrics.</span>

            </div>
        }
        ErrorBoundary={LexicalErrorBoundary}
      />
      <OnChangePlugin onChange={onChange} />
      {type === "Metric" &&
        <>
          <MetricsAutoCompletePlugin metrics={metrics} concepts={concepts} />
          {/* <ConceptsAutoCompletePlugin conceptNames={conceptNames} /> */}
        </>
      }
      {/* <QuotedTextPlugin /> */}
      <BracketTextPlugin metrics={metrics} concepts={concepts} />
      <BraceTextPlugin />
      <AutoFocusPlugin />
    </div>
  );
};

export default TextEditor;
