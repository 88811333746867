import React, { useState, useEffect } from 'react';
import { useMetrics } from '../hooks/useMetrics';
import { useAuth } from '../hooks/useAuth';
import { useLibrary } from '../hooks/useLibrary';
import TextEditor from './TextEditor';
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import Buttons from "./Buttons";
import CodeContainer from './CodeContainer';
import TableContainer from "./TableContainer";
import Parameters from './Parameters';
import Loader from './Loader';
import AttributeBox from './AttributeBox';
import AttributeList from './AttributeList';
import ItemBox from './ItemBox';
import CollapsibleSection from './CollapsableSection';
import { Attribute, Detail, Filter } from "../@types/common";
import { useToast } from "../hooks/useToast"
import { ReactComponent as StartIcon } from "../svg/start_icon.svg"
import { $getRoot, $createTextNode, $createParagraphNode } from "lexical";

interface TableColumn {
    COLUMN_NAME: string;
    DATA_TYPE: string;
}

const ConceptPanel: React.FC = () => {
    const { type, item, items, setItem, setItems, addItem } = useMetrics();
    const [status, setStatus] = useState<string>("empty");
    const [id, setId] = useState<number>(-1);
    const [sql, setSQL] = useState<string>("");
    const [editor] = useLexicalComposerContext();
    const [executionResult, setExecutionResult] = useState<string | null>(null);
    const [tableHead, setTableHead] = useState<string[]>([]);
    const [tableData, setTableData] = useState<any[]>([]);
    const [quotedTexts, setQuotedTexts] = useState<string[]>([]);
    const [params, setParams] = useState<string[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [selectedAttribute, setSelectedAttribute] = useState<Attribute | null>(null);
    const toast = useToast();
    const { userProfile } = useAuth();
    const { library } = useLibrary();

    const handleAdd = (type: string) => {
        let newItem = { ...item };
        if (type === "attribute") {
            const newAttribute: Attribute = { id: id, name: 'New attribute', desc: '', mappers: [], linker: '', data_connection: '' };
            newItem.attributes = [...(newItem.attributes || []), newAttribute];
            setSelectedAttribute(newAttribute);
            setId(id - 1);
        }
        setItem(newItem);
    };

    const executeSQL = async () => {
        // setLoading(true);
        const response = await fetch(`/api/execute_sql?email=${userProfile?.email}`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                "sql_statement": item.sql || "",
            }),
        });

        const responseData = await response.json();
        console.log(responseData)
        // setExecutionResult(JSON.stringify(responseData, null, 2));
        setTableHead(responseData.column_names);
        setTableData(responseData.records);
        setLoading(false);
        console.log("Hello???")
    };

    const getButtonClass = (buttonType: string) => {
        return buttonState(buttonType)
            ? "rounded-md border border-black px-2 w-[120px] h-[50px] bg-gray-100"
            : "rounded-md border border-gray-300 px-2 w-[120px] h-[50px] text-gray-300";
    };

    const buttonState = (buttonType: string) => {
        if (buttonType === 'Delete') return true;
        if (buttonType === 'VerifiedState') return true;
        if (buttonType === 'Verified') return item.status !== 'Verified';

        const curItem = items.filter(it => it.id === item.id && it.type === item.type)[0];

        if (buttonType === 'Generate') return curItem.logic !== item.logic;
        if (buttonType === 'Execute') return item.sql !== "";
        if (buttonType === 'Duplicate') return item.id !== -1;
        return curItem !== item;
    };

    const handleButtonClick = async (buttonType: string) => {
        if (buttonType === "Dismiss") {
            const orig = items.filter(it => it.id === item.id && it.type === item.type)[0];
            setItem(orig);
            editor.update(() => {
                const root = $getRoot();
                root.clear();
                const paragraph = $createParagraphNode();
                const text = $createTextNode(orig.logic || "");
                paragraph.append(text);
                root.append(paragraph);
            });
            toast.success("Dismissed edits!");
            return;
        }

        if (buttonType === "Duplicate") {
            const copyItem = { ...item, name: "Copy of " + item.name };
            addItem(copyItem);
            return;
        }

        let status = "";
        if (buttonType === "Delete") status = "Deprecated";
        if (buttonType === "Draft") status = "Draft";
        if (buttonType === "Verified") {
            status = item.status !== "Verified" ? "Verified" : "Draft";
        }

        let newItem = {
            ...item,
            logic: editor.getRootElement()?.textContent || "",
            status,
        };

        const typeName = type.charAt(0).toLowerCase() + type.slice(1);
        const data = {
            [`${typeName}_id`]: newItem.id,
            [`${typeName}_name`]: newItem.name,
            "category_name": newItem.category || "",
            [`${typeName}_desc`]: newItem.desc || "",
            "sql_statement": newItem.sql || "",
            "modified_sql": newItem.modified_sql || "N",
            "logic": newItem.logic || "",
            "input_fields": newItem.input_fields || "",
            "output_fields": newItem.output_fields || "",
            "formula": newItem.formular || "",
            "status": newItem.status,
            "deprecated": status === "Deprecated" ? "D" : (newItem.deprecated || ""),
            "attributes": newItem.attributes || [],
            "links": newItem.links || [],
            "dimensions": newItem.dimensions || [],
            "filters": newItem.filters || [],
        };
        console.log(newItem)

        const response = await fetch(`/api/save_${typeName}?email=${userProfile?.email}&library_id=${library?.id}`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(data),
        });

        const responseData = await response.json();
        console.log(responseData);
        if (responseData.id) {
            newItem.id = responseData.id;
        };

        if (buttonType === "Delete") {
            const updatedItems = items.filter((currentItem) => currentItem.id !== item.id || currentItem.type !== item.type);
            setItems(updatedItems);
            setItem({ id: -1, name: "", type: "" });
            toast.success("Item deleted successfully!");
        } else {
            setItem(newItem);
            const updatedItems = items.map((currentItem) => (currentItem.id === item.id && currentItem.type === item.type) ? newItem : currentItem);
            setItems(updatedItems);
            toast.success("Saved successfully!");
        }
    };

    const parseSQL = async (sqlStatement: string) => {
        try {
            const response = await fetch('/api/parse_sql', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ sql_statement: sqlStatement }),
            });

            if (response.status !== 200) {
                throw new Error("Network response was not ok");
            }

            const responseData = await response.json();
            console.log(responseData);

            if (responseData.message === "success") {
                let idCounter = -1;
                const addId = (item: any) => {
                    item.id = idCounter;
                    idCounter--;
                    return item;
                };

                const newItem = {
                    ...item,
                    attributes: responseData.attributes.map(addId),
                    filters: responseData.filters.map(addId),
                    dimensions: responseData.dimensions.map(addId),
                };
                setItem(newItem);
                toast.success("SQL parsed successfully!");
            } else {
                toast.error("Failed to parse SQL.");
            }
        } catch (error) {
            toast.error("Error occurred: " + error);
        }
    };

    if (!item.type) {
        return (
            <div className="space-y-4 grow max-w-3xl flex flex-col items-center justify-center">
                <StartIcon className="w-150 h-150" />
                <div className='font-semibold text-center'>To get started, create a new concept or new metric,
                    or select an existing metric or concept to view and edit it</div>
                <div className='text-center'>Concepts are low level building blocks connecting directly to your data.</div>
                <div className='text-center'>Metrics string together Concepts and other Metrics describing more complex ideas that you want to measure.</div>
            </div>
        );
    }

    return (
        <div className="space-y-4 grow max-w-3xl pt-10">
            <Buttons type={"Concept"} handleButtonClick={handleButtonClick} buttonState={buttonState} />
            <div className="flex items-center border border-black p-2">
                <label className="w-1/4">{type} Name:</label>
                <input className={`w-3/4 p-2 ${item.status === "Verified" && 'opacity-50'}`} type="text" placeholder="Enter name" value={item.name !== `Untitled ${type}` ? item.name : ''}
                    disabled={item.status === "Verified"}
                    onChange={(e) => { setItem({ ...item, name: e.target.value }) }} />
            </div>
            <div className="flex items-center border border-black p-2">
                <label className="w-1/4">Summary Description:</label>
                <input className={`w-3/4 p-2 ${item.status === "Verified" && 'opacity-50'}`} type="text" value={item.desc || ''} placeholder="Enter description..."
                    disabled={item.status === "Verified"}
                    onChange={(e) => { setItem({ ...item, desc: e.target.value }) }} />
            </div>
            <div className="border border-black p-4 mb-4">
                <div className="flex">
                    <div className="font-semibold mr-2">SQL:</div>
                    <div className="text-gray-600">Enter the SQL to retrieve the data related to this concept</div>
                </div>
                <CodeContainer code={item.sql || ""} status={status} isUserModified={item.modified_sql || ""}
                    handleValueChange={(newSql: string) => { setItem({ ...item, sql: newSql, modified_sql: "Y" }) }}
                    disabled={item.status === "Verified"} />
                <button onClick={e => parseSQL(item.sql || "")} className="border border-black p-2 rounded mr-2 mt-2">Parse SQL</button>
                <button onClick={executeSQL} className="border border-black p-2 rounded mt-2">Execute SQL</button>
            </div>
            {tableHead.length > 0 && <div className="w-full border-2 border-gray-300 p-2">
                {loading ? (
                    <Loader />
                ) : (
                    <TableContainer input='' sql='' head={tableHead} data={tableData} id='' />
                )}
            </div>}
            <div className="flex mb-4">
                <div className="border border-black p-4 w-full">
                    <div className="flex justify-between items-center mb-2">
                        <div className="font-semibold mr-2">Attributes:</div>
                        <div className="text-gray-600 text-sm">Identify the objects making up the concept and map them to their respective data fields</div>
                    </div>
                    <AttributeList items={item.attributes || []} item={item} setItem={setItem} />
                    <button
                        className="text-sm font-semibold"
                        onClick={() => handleAdd("attribute")}
                    >
                        + Add Attribute
                    </button>
                </div>
            </div>
        </div>
    )
};

export default ConceptPanel;
