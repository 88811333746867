import { UserProfile } from "../hooks/useAuth";

export const googleOAuth = async (credential: string) => {
  const response = await fetch("/api/oauth/google", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ token: credential }),
  });

  const responseData = await response.json();
  const info = responseData.idinfo;
  return {
    id: info.id as string,
    email: info.email as string,
    name: info.name as string,
    picture: info.picture as string,
    expiresAt: info.exp as number,
  } as UserProfile;
};