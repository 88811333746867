import React, { Fragment } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../hooks/useAuth";
import { Menu, Transition } from "@headlessui/react";

interface UserProfileProps {
    className: string
}


const UserProfile: React.FC<UserProfileProps> = ({className}) => {
  const { userProfile, setUserProfile } = useAuth();
  const navigate = useNavigate();

  const handleLogout = () => {
    setUserProfile(null);
    navigate("/login");
  };

  return (
    <Menu as="div" className={className}>
      <Menu.Button
        className="rounded-full box-content border-2 border-gray-50 hover:border-blue-300 hover:shadow-lg"
        role="button"
      >
        <img className="h-full w-full rounded-full" src={userProfile?.picture} alt="avatar"></img>
      </Menu.Button>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute flex flex-col transform -translate-x-full p-1 min-w-[100px] divide-y divide-gray-100 rounded-md bg-gray-50 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none z-10">
          <div className="flex p-4">
            <Menu.Item>
              <span>
                <p className="w-full whitespace-nowrap py-1 text-gray-900">
                  Hello, <span className="font-bold">{userProfile?.name}</span>
                </p>
                <p className="text-gray-500">{userProfile?.email}</p>
              </span>
            </Menu.Item>
          </div>
          <div className="px-1 py-1 ">
            <Menu.Item>
              <button
                className="w-full py-1 text-gray-900 rounded-md hover:bg-gray-200"
                onClick={handleLogout}
              >
                Logout
              </button>
            </Menu.Item>
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
};

export default UserProfile;
