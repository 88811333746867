import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { TableDataProvider } from "./hooks/useTableData";
import { MetricsProvider } from "./hooks/useMetrics";
import { AuthProvider } from "./hooks/useAuth";
import ToastProvider from "./hooks/useToast";
import { LibraryProvider } from "./hooks/useLibrary";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <ToastProvider>
    <AuthProvider>
      <MetricsProvider>
        <TableDataProvider>
          <LibraryProvider>
            <React.StrictMode>
              <App />
            </React.StrictMode>
          </LibraryProvider>
        </TableDataProvider>
      </MetricsProvider>
    </AuthProvider>
  </ToastProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
